import { forwardRef, useMemo } from "react";
import { cx } from "@libs/utils/cx";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as GoogleIcon } from "@libs/assets/icons/google.svg";
import { formatCustomState } from "@libs/auth/customState";
import { signInWithRedirect, SignInWithRedirectInput } from "@aws-amplify/auth";
import { usePatientActivityStorage } from "storage/activity";
import { paths } from "router/paths";

type AuthProvider = Exclude<NonNullable<SignInWithRedirectInput["provider"]>, { custom: string }>;
// Converts enum to union:
type Props = {
  provider: AuthProvider;
  returnUrl?: string;
  lastUserId?: number;
};

const PROVIDER_ICON_MAP: Record<AuthProvider, IconComponent> = {
  ["Google"]: GoogleIcon,
  ["Facebook"]: () => null,
  ["Amazon"]: () => null,
  ["Apple"]: () => null,
};

export const ThirdPartySignInButton = forwardRef<
  HTMLButtonElement,
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    { className, children, returnUrl, lastUserId, type = "button", disabled = false, provider, ...rest },
    ref
  ) => {
    const activityStorage = usePatientActivityStorage();
    const customState = useMemo(
      () => formatCustomState({ lastUserId, returnUrl: returnUrl ?? paths.home() }),
      [returnUrl, lastUserId]
    );

    return (
      <button
        {...{ ...rest, disabled }}
        onClick={() => {
          activityStorage.setRecentlyActive();
          signInWithRedirect({ customState, provider });
        }}
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={cx(
          `flex
           items-center
           px-4
           py-3
           relative
           bg-white
           rounded-md
           font-sansSemiBold
           gap-2
           border-[#DADCE0]
           border
           hover:border-primaryTheme
           hover:border-opacity-70
           text-sm
           text-center
           text-greyDark
           hover:text-greyMedium`,
          className
        )}
      >
        <div className="absolute left-4 top-3">
          <Icon size="md" SvgIcon={PROVIDER_ICON_MAP[provider]} />
        </div>
        <div className="flex-1 text-center">{children}</div>
      </button>
    );
  }
);
