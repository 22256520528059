import { FormSectionVO, PracticeInfoVO, ProcedurePriorityOptionVO } from "@libs/api/generated-api";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { Box } from "components/PatientForms/FormPDFElements/Box";
import { PatientFormContentItem } from "components/PatientForms/FormPDFElements/PatientFormContentItem";
import { ExistingPatientInfo } from "components/PatientForms/FormElements/types";

type Props = {
  element: FormSectionVO;
  responsesById: PatientResponses;
  patient: ExistingPatientInfo;
  practice: PracticeInfoVO;
  priorityOptions: ProcedurePriorityOptionVO[];
  // Used when printing to allow user to fill out answer
  edit?: boolean;
};
export const PatientFormSection: React.FC<Props> = ({
  element,
  edit = false,
  responsesById,
  patient,
  practice,
  priorityOptions,
}) => {
  const { title, content } = element;

  return (
    <Box>
      <Box>
        <Text bold size="lg">
          {title}
        </Text>
      </Box>

      {content.map((item) => {
        return (
          <PatientFormContentItem
            key={item.uuid}
            priorityOptions={priorityOptions}
            contentItem={item}
            patient={patient}
            responsesById={responsesById}
            edit={edit}
            practice={practice}
          />
        );
      })}
    </Box>
  );
};
