import { captureException } from "@sentry/react";
import { fetchAuthSession } from "@aws-amplify/auth";
import { IDENTITY_EMAIL_NOT_FOUND, getTokenErrorReason } from "@libs/utils/cognito";
import { getAccountTokenStorage } from "@libs/storage/accountToken";

type KnownCognitoGroup = "admin-portal-supportusers" | "practice-portal-impersonation";
type IdentityTokenAttributes = {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  cognitoGroups: Set<KnownCognitoGroup>;
};
export const getIdentityToken = async () => {
  const session = await fetchAuthSession();
  const idToken = session.tokens?.idToken;
  const jwt = session.tokens?.idToken?.toString();

  const userFields = [
    { field: "username" as const, cognitoField: "cognito:username" as const },
    { field: "email" as const, cognitoField: "email" as const },
    { field: "firstName" as const, cognitoField: "given_name" as const },
    { field: "lastName" as const, cognitoField: "family_name" as const },
    { field: "cognitoGroups" as const, cognitoField: "cognito:groups" as const },
  ].reduce<IdentityTokenAttributes>((acc, { field, cognitoField }) => {
    if (typeof idToken?.payload[cognitoField] === "string" && field !== "cognitoGroups") {
      acc[field] = idToken.payload[cognitoField];
    } else if (field === "cognitoGroups" && Array.isArray(idToken?.payload[cognitoField])) {
      acc[field] = new Set(idToken.payload[cognitoField] as KnownCognitoGroup[]);
    }

    return acc;
  }, {} as IdentityTokenAttributes);

  if (!userFields.email || !userFields.username) {
    throw new Error(IDENTITY_EMAIL_NOT_FOUND);
  }

  return {
    token: jwt,
    ...userFields,
  };
};

const getTokens = async (storage: Storage) => {
  const identity = await getIdentityToken();
  const accountTokenStorage = getAccountTokenStorage(storage);

  return {
    identity,
    account: accountTokenStorage.getAccountToken(identity.email) ?? undefined,
  };
};

// Allow errors to bubble so UI can take action on token errors
export const getTokensForApi = async (storage: Storage) => {
  const tokens = await getTokens(storage);

  if (!tokens.identity.token) {
    throw new Error("No current user");
  }

  return {
    identity: tokens.identity.token,
    account: tokens.account?.token,
  };
};

// Log errors but if they happen the users are considered unauthenticated
export const getTokensForAuthCheck = async (storage: Storage) => {
  try {
    return await getTokens(storage);
  } catch (e) {
    if (!getTokenErrorReason(e)) {
      captureException(e);
    }

    return {
      identity: undefined,
      account: undefined,
    };
  }
};
