import React, { ReactNode, useCallback, useMemo } from "react";
import { pdf } from "@react-pdf/renderer";
import { Breadcrumb, addBreadcrumb } from "@sentry/react";
import { PatientFormTasksResponse, PracticeInfoVO } from "@libs/api/generated-api";
import { blobToFile } from "@libs/utils/dataUrl";
import { useBoolean } from "@libs/hooks/useBoolean";
import { MINUTE_IN_SECONDS, SECOND_IN_MS } from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { TaskProgressHeader } from "@libs/components/UI/TaskProgressHeader";
import { half } from "@libs/utils/math";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { updateCachedData } from "@libs/utils/queryCache";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@libs/utils/queries";
import { produce } from "immer";
import { getProcedurePriorityOptions } from "@libs/api/charting/queries";
import { LoadedPatientForm } from "components/PatientForms/LoadedPatientForm";
import { PatientResponses, ResponseValue } from "components/PatientForms/hooks/usePatientResponses";
import { patientSubmitFormTask, prepareFileUploadForFormTask } from "api/forms/mutations";
import { PatientFormPdf } from "components/PatientForms/FormPDFElements/PatientFormPdf";
import { useHandleError } from "api/handleErrorResponse";
import { FormTaskChrome } from "components/PatientForms/PatientFormTasks/FormTaskChrome";
import { getPracticeLogoWithDimensions } from "components/PatientForms/FormPDFElements/utils/getPracticeLogo";
import { IdleTimer } from "components/Main/IdleTimer";
import { updatePatientDetails } from "api/patient/mutations";
import { EditablePatientInfoFields } from "components/PatientForms/FormElements/types";
import { getPatientReferredByOptions } from "api/forms/queries";

const EMPTY_RESPONSES = {};

type Props = {
  formTasksResponse: PatientFormTasksResponse;
  practice: PracticeInfoVO;
  patientFormToken: string;
  completedScreen: ReactNode;
  reloadAfterIdleMinutes: number;
};

export const LoadedPatientFormTasks: React.FC<Props> = ({
  formTasksResponse,
  practice,
  patientFormToken,
  completedScreen,
  reloadAfterIdleMinutes,
}) => {
  const queryClient = useQueryClient();
  const submitting = useBoolean(false);
  const practiceUuid = practice.uuid;
  const currentFormTask = React.useMemo(
    () => formTasksResponse.tasks.find((task) => task.state === "PENDING"),
    [formTasksResponse.tasks]
  );
  const idlePrompt = useBoolean(false);

  const [getPatientReferredByOptionsQuery, procedurePriorityOptionsQuery] = useApiQueries([
    getPatientReferredByOptions({
      args: { practiceUuid: practice.uuid, dob: formTasksResponse.dob, patientFormToken },
      queryOptions: {
        enabled: currentFormTask?.form.slug === "GENERAL_INFO",
      },
    }),
    getProcedurePriorityOptions({ args: { practiceUuid } }),
  ]);

  const [patientSubmitFormTaskMutation, prepareFileUploadForFormTaskMutation, updatePatientDetailsMutation] =
    useApiMutations([patientSubmitFormTask, prepareFileUploadForFormTask, updatePatientDetails]);
  const patientSubmitFormTaskMutateAsync = patientSubmitFormTaskMutation.mutateAsync;
  const prepareFileUploadForFormTaskMutateAsync = prepareFileUploadForFormTaskMutation.mutateAsync;
  const updatePatientInfoMutateAsync = updatePatientDetailsMutation.mutateAsync;
  const handleError = useHandleError();

  const handleSubmit = React.useCallback(
    async (submission: {
      responsesById: PatientResponses;
      formPublishedContentUuid: string;
      updatePatientInfo?: EditablePatientInfoFields;
    }) => {
      if (!currentFormTask) {
        return;
      }

      const { form, uuid: formTaskUuid } = currentFormTask;

      if (!form.publishedContentUuid) {
        return;
      }

      submitting.on();

      const breadCrumb: Pick<Breadcrumb, "level" | "category"> = {
        level: "info",
        category: "form-tasks",
      };

      try {
        if (submission.updatePatientInfo) {
          addBreadcrumb({
            ...breadCrumb,
            message: "Updating patient details",
          });
          await updatePatientInfoMutateAsync({
            practiceUuid,
            data: submission.updatePatientInfo,
            patientId: formTasksResponse.patientId,
            dob: formTasksResponse.dob,
            patientFormToken,
          });
        }

        addBreadcrumb({
          ...breadCrumb,
          message: "Generating form task PDF",
        });

        // const logo = await getPracticeLogoBase64(practice);
        const logo = await getPracticeLogoWithDimensions(practice);

        const formPdfBlob = await pdf(
          <PatientFormPdf
            practice={practice}
            patient={formTasksResponse}
            formData={form}
            responses={submission.responsesById}
            logo={logo}
            priorityOptions={procedurePriorityOptionsQuery.data ?? []}
            patientInfoSubmission={submission.updatePatientInfo}
          />
        ).toBlob();

        addBreadcrumb({
          ...breadCrumb,
          message: "Fetching form task upload url",
        });

        const fileUploadResponse = await prepareFileUploadForFormTaskMutateAsync({
          practiceUuid,
          formTaskUuid,
          patientFormToken,
          dob: formTasksResponse.dob,
        });
        const fileUpload = fileUploadResponse.data.data;

        addBreadcrumb({
          ...breadCrumb,
          message: "Submitting Form task PDF",
          data: {
            tokenExpiresAt: fileUpload.expiresAt,
            now: Date.now() / SECOND_IN_MS,
          },
        });

        const response = await fetch(fileUpload.url, {
          method: "PUT",
          body: blobToFile(formPdfBlob, `${form.title}.pdf`),
        });

        if (!response.ok) {
          throw new Error(await response.text());
        }

        addBreadcrumb({
          ...breadCrumb,
          message: "Submitting form task response",
        });

        await patientSubmitFormTaskMutateAsync({
          practiceUuid,
          formTaskUuid,
          data: {
            encryptedFileKey: fileUpload.encryptedFileKey,
            formResponse: {
              responses: submission.responsesById as Record<string, ResponseValue>,
              formPublishedContentUuid: form.publishedContentUuid,
            },
          },
          dob: formTasksResponse.dob,
          patientFormToken,
        });

        // If a patient's gender was updated subsequent forms
        // should see that update
        if (submission.updatePatientInfo?.gender) {
          updateCachedData<PatientFormTasksResponse>(
            queryClient,
            {
              queryKey: [getQueryKey("public", "getPatientFormTasks"), { practiceUuid, patientFormToken }],
            },
            (patientFormTasksResponse) => {
              return produce(patientFormTasksResponse, (draft) => {
                draft.gender = submission.updatePatientInfo?.gender;
              });
            }
          );
        }

        addBreadcrumb({
          ...breadCrumb,
          message: "Successfully submitted form task",
        });
      } catch (e) {
        handleError(e);
      }

      submitting.off();
    },
    [
      currentFormTask,
      handleError,
      patientSubmitFormTaskMutateAsync,
      updatePatientInfoMutateAsync,
      practice,
      practiceUuid,
      formTasksResponse,
      prepareFileUploadForFormTaskMutateAsync,
      submitting,
      patientFormToken,
      queryClient,
      procedurePriorityOptionsQuery.data,
    ]
  );

  const handleIdle = useCallback(() => {
    window.location.reload();
  }, []);

  const idleConfig = useMemo(() => {
    const IDLE_TIMEOUT = MINUTE_IN_SECONDS * SECOND_IN_MS * reloadAfterIdleMinutes;

    return {
      // Reloads page after six minutes (3 minutes of inactivity, 3 minutes with prompt)
      timeout: IDLE_TIMEOUT,
      // Prompts user after 3 minutes
      promptBeforeIdle: half(IDLE_TIMEOUT),

      eventsThrottle: 10 * SECOND_IN_MS,
      name: "formTasks",
    };
  }, [reloadAfterIdleMinutes]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentFormTask?.uuid]);

  return currentFormTask ? (
    <FormTaskChrome
      practice={practice}
      patientName={`${formTasksResponse.firstName} ${formTasksResponse.lastName}`}
    >
      <IdleTimer {...idleConfig} onIdle={handleIdle} onTogglePrompt={idlePrompt.set} />

      <TaskProgressHeader
        totalSteps={formTasksResponse.tasks.length}
        step={formTasksResponse.tasks.indexOf(currentFormTask)}
        className="px-4 mt-4"
      />
      <LoadedPatientForm
        key={currentFormTask.uuid}
        formData={currentFormTask.form}
        responses={EMPTY_RESPONSES}
        patient={formTasksResponse}
        edit
        showInWizard
        uuidOrSlug={currentFormTask.form.uuid}
        onSubmit={handleSubmit}
        fixedFooter
        warnWhenNavigating={!idlePrompt.isOn}
        isSubmitting={submitting.isOn}
        practice={practice}
        priorityOptions={procedurePriorityOptionsQuery.data ?? []}
        referralOptions={getPatientReferredByOptionsQuery.data?.referralOptions ?? []}
      />
    </FormTaskChrome>
  ) : (
    completedScreen
  );
};
