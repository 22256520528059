import { FormVO, PracticeInfoVO, ProcedurePriorityOptionVO } from "@libs/api/generated-api";
import { formatDate } from "@libs/utils/date";
import { EditablePatientInfoFields, ExistingPatientInfo } from "components/PatientForms/FormElements/types";
import { PatientFormContentItem } from "components/PatientForms/FormPDFElements/PatientFormContentItem";
import { PDFDocument } from "components/PatientForms/FormPDFElements/PDFDocument";
import { PracticePDFHeader } from "components/PatientForms/FormPDFElements/PracticePDFHeader";
import { useFilteredForm } from "components/PatientForms/hooks/useFilteredForm";
import { PatientResponses, usePatientResponses } from "components/PatientForms/hooks/usePatientResponses";

export type Props = {
  practice: PracticeInfoVO;
  patient: ExistingPatientInfo;
  patientInfoSubmission?: EditablePatientInfoFields;
  formData: FormVO;
  responses: PatientResponses;
  priorityOptions: ProcedurePriorityOptionVO[];
  logo?: {
    w: number;
    h: number;
    url: string;
  };
};
export const PatientFormPdf: React.FC<Props> = ({
  formData,
  responses,
  patient,
  practice,
  logo,
  patientInfoSubmission,
  priorityOptions,
}) => {
  const matchedForm = useFilteredForm({
    practice,
    form: formData,
    patientAttributes: patient,
  });
  const { responsesById } = usePatientResponses({
    latestForm: formData,
    latestResponses: responses,
    reconsent: false,
  });

  return (
    <PDFDocument title={matchedForm.title} subject={matchedForm.description} practice={practice}>
      <PracticePDFHeader
        practice={practice}
        patient={patient}
        dateCompleted={formatDate(new Date())}
        logo={logo}
      />

      {matchedForm.content.map((item) => {
        return item.content.map((contentItem) => (
          <PatientFormContentItem
            key={item.uuid}
            priorityOptions={priorityOptions}
            contentItem={contentItem}
            patient={patient}
            responsesById={responsesById}
            practice={practice}
            patientInfoSubmission={patientInfoSubmission}
            edit={false}
          />
        ));
      })}
    </PDFDocument>
  );
};
