import React, { FormEvent } from "react";
import OTPInput from "otp-input-react";
import { useTranslation } from "react-i18next";
import { confirmSignUp, resendSignUpCode } from "@aws-amplify/auth";
import { cx } from "@libs/utils/cx";
import { useValidation } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Form } from "@libs/components/UI/Form";
import { ResendCode } from "components/ForgotPassword/ResendCode";
import { getOtpValidationSchema } from "components/SignIn/validationUtil";
import { cxSignInStyles } from "components/SignIn/cxSignInStyles";
import { useAuthErrorHandler } from "hooks/useAuthErrorHandler";

interface Props {
  email: string;
  password?: string;
  onEmailConfirmed: Func;
}

export const OTP_LENGTH = 6;

const cxStyles = {
  verificationCode: "w-10 mr-2.5 box-border rounded-[5px]",
};

const ErrorMessage = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <div className="flex mt-2 text-red-500 text-xs">
      <ErrorIcon className="h-4 w-4 mr-1" />
      {errorMessage}
    </div>
  );
};

export const OneTimeCodeForm: React.FC<Props> = ({ email, onEmailConfirmed }) => {
  const { t } = useTranslation();
  const [otp, setOtp] = React.useState("");
  const loading = useBoolean(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const otpForm = useValidation(
    {
      otp,
    },
    {
      otp: getOtpValidationSchema(otp, t("cognito.CodeMismatchException")),
    }
  );
  const otpErrorMessage = React.useMemo(() => {
    if (otpForm.result.otp.$error) {
      return otpForm.result.otp.$error;
    }

    return "";
  }, [otpForm.result.otp.$error]);
  const { handleAuthError } = useAuthErrorHandler(setErrorMessage);
  const handleResendCode = React.useCallback(async () => {
    loading.on();

    try {
      await resendSignUpCode({ username: email });
    } catch (err) {
      handleAuthError(err);
    }

    loading.off();
  }, [email, handleAuthError, loading]);
  const handleOneTimeCodeSubmit = React.useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const validate = otpForm.validate();

      if (!validate.$isValid) {
        return;
      }

      loading.on();

      try {
        await confirmSignUp({ username: email, confirmationCode: otp });
      } catch (err) {
        handleAuthError(err);
        loading.off();

        return;
      }

      onEmailConfirmed();

      loading.off();
    },
    [otpForm, loading, email, otp, handleAuthError, onEmailConfirmed]
  );

  return (
    <Form id="one-timecode-form" className="space-y-5" onSubmit={handleOneTimeCodeSubmit}>
      <div className="font-sans text-sm">Please enter the code sent to your email address.</div>
      <div className="space-y-3">
        <div className="font-sans text-sm">Verification Code</div>
        <OTPInput
          autoFocus
          disabled={false}
          inputClassName={
            otpForm.result.otp.$error || errorMessage
              ? cx("border border-red-500", cxStyles.verificationCode)
              : cx("border border-greyLight", cxStyles.verificationCode)
          }
          // Styles set in `inputStyles` take precedence over
          // `inputClassName` and we need to override the built-in
          // defaults which forces us to style here and in the CSS class.
          inputStyles={{ marginRight: "10px", height: "40px" }}
          onChange={(value: string) => setOtp(value)}
          OTPLength={OTP_LENGTH}
          otpType="number"
          placeholder="999999"
          value={otp}
        />
        {errorMessage ? (
          <div className="flex items-end">
            <ErrorMessage errorMessage={errorMessage} />
          </div>
        ) : (
          <>
            {otpErrorMessage && <ErrorMessage errorMessage={otpErrorMessage} />}
            <div className="flex mt-3">
              <span className="mr-1 font-sans text-sm">{t("app.page.labels.dontReceiveCode")}</span>
              <ResendCode onClick={handleResendCode} />
            </div>
          </>
        )}
      </div>
      <AsyncButton
        className={cxSignInStyles.button}
        displayLoadingText
        isLoading={loading.isOn}
        type="submit"
        disabled={otp.length < OTP_LENGTH}
      >
        {loading.isOn ? "Sending" : "Confirm Password"}
      </AsyncButton>
    </Form>
  );
};
